import React from 'react';
import SectionLayout from '../../../layouts/SectionLayout';
import droneOverviewImg from '../../../images/cai_services_dronecapture.png';
import SEO from '../../../components/SEO';

export default function DroneServices() {
  const cdnBaseUrl =
    process.env.GATSBY_CDN_URL || 'https://cdn.cultivateagi.com';
  return (
    <>
      <SEO title="Services - Drone" />
      <SectionLayout>
        <p className="section-display-subheader">
          HIDDEN PLANT HEALTH. REVEALED.
        </p>
        <div className="row mobile-desktop-text-margin">
          <div className="col-sm-12">
            <p>
              We hire certified FAA Pilots and use professional grade drones
              with Near-Infrared sensors to capture high resolution, spatial and
              spectral detail of your fields. Visualize vegetation health
              invisible to the naked eye—such as plant separation, stress, and
              biomass.
            </p>
          </div>
          <div className="col-sm-12 text-center">
            <a
              href={`${cdnBaseUrl}/marketing/products/CAI_DroneCapture_1Page.pdf`}
              download
            >
              Download more info here.
            </a>
          </div>
        </div>

        <div className="row mobile-desktop-img-margin">
          <div className="col-sm-12 text-center">
            <img
              className="img-fluid"
              alt="drone services overview img"
              src={droneOverviewImg}
            />
          </div>
        </div>
      </SectionLayout>
    </>
  );
}
